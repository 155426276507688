import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import {
	Helmet,
	I18nextContext,
	useI18next,
	useTranslation,
} from 'gatsby-plugin-react-i18next'
import type { IndexQuery } from '@graphql-types'
import { css, useTheme } from '@emotion/react'
import { useContext, useEffect } from 'react'
import SEO from '@components/SEO'
import Container from '@components/Container'
import FeaturedBy from '@components/FeaturedBy'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ButtonLink } from '@components/ButtonLink'
import { maxWidth } from 'src/themes'

const IndexPage = (props: PageProps<IndexQuery>) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const { changeLanguage } = useI18next()
	const context = useContext(I18nextContext)

	useEffect(() => {
		const locale = localStorage.getItem('locale')
		if (locale !== null && locale !== context.language && !context.routed) {
			changeLanguage(locale)
		}
	}, [changeLanguage, context])

	return (
		<Container>
			<SEO title={t('home')} keywords={[]} />

			<div
				css={css`
					position: relative;
					width: 100%;
					display: flex;
					justify-content: flex-end;

					${maxWidth[2]} {
						flex-direction: column;
						align-items: center;
						gap: 2rem;
					}
				`}>
				<div
					css={css`
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: flex-start;
						z-index: 2;

						${maxWidth[2]} {
							position: static;
							text-align: center;
							align-items: center;
						}
					`}>
					<h1
						css={css`
							font-size: 2.5rem;
						`}>
						{t('tecky_slogan')}
					</h1>
					<ButtonLink to='/courses'>{t('discover_more')}</ButtonLink>
				</div>
				<GatsbyImage
					image={props.data.splash?.childImageSharp?.gatsbyImageData}
					alt={t('splash_image')}
				/>
			</div>

			<FeaturedBy
				media={props.data.allCockpitMediaLogosCollection.nodes}
			/>
		</Container>
	)
}

export default IndexPage

export const query = graphql`
	query Index($language: String!) {
		...TranslationFragment
		splash: file(relativePath: { eq: "splash.png" }) {
			childImageSharp {
				gatsbyImageData(width: 600, placeholder: BLURRED)
			}
		}
		allCockpitMediaLogosCollection(filter: { _lang: { eq: $language } }) {
			nodes {
				title
				mediaLogo {
					path
				}
				mediaLogoSharp {
					childImageSharp {
						gatsbyImageData(width: 80, placeholder: BLURRED)
					}
				}
			}
		}
	}
`
