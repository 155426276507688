import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { SeoQuery } from '@graphql-types'
import { useTranslation } from 'react-i18next'
import { useI18next } from 'gatsby-plugin-react-i18next'

export default function SEO({
	description,
	keywords,
	title,
}: {
	description?: string
	keywords?: string[]
	title: string
}) {
	const { t } = useTranslation()
	const {
		languages,
		language,
		originalPath,
		defaultLanguage,
		siteUrl = '',
	} = useI18next()
	const createUrlWithLang = (lng: string) => {
		const url = `${siteUrl.replace(/\/$/, '')}${`/${lng}`}${originalPath}`
		return url.endsWith('/') ? url : `${url}/`
	}

	const data: SeoQuery = useStaticQuery(graphql`
		query SEO {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
	`)
	const metaDescription =
		description ?? data.site?.siteMetadata?.description ?? ''
	let metaKeywords = [
		'tecky',
		'academy',
		'coding',
		'bootcamp',
		'micromaster',
		'programming',
	]

	if (keywords != null) {
		metaKeywords = metaKeywords.concat(keywords)
	}

	return (
		<Helmet
			htmlAttributes={{
				lang: t('locale'),
			}}
			defer={false}
			title={title}
			titleTemplate={`%s | ${data.site?.siteMetadata?.title}`}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: data.site?.siteMetadata?.author ?? '',
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					name: `keywords`,
					content: metaKeywords.join(`, `),
				},
			]}>
			<link rel='canonical' href={createUrlWithLang(language)} />
			{languages.map((lng) => (
				<link
					rel='alternate'
					key={lng}
					href={createUrlWithLang(lng)}
					hrefLang={
						lng === 'en'
							? 'en-HK'
							: lng === 'zh_Hant'
							? 'zh-HK'
							: lng
					}
				/>
			))}
			{/* adding a fallback page for unmatched languages */}
			<link
				rel='alternate'
				href={createUrlWithLang(defaultLanguage)}
				hrefLang='x-default'
			/>
		</Helmet>
	)
}
