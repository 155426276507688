import React from 'react'
import { css, useTheme } from '@emotion/react'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IndexQuery } from '@graphql-types'
import { H2Highlight } from './H2Highlight'
import { maxWidth } from 'src/themes'
import { GatsbyImage } from 'gatsby-plugin-image'

export type Media = NonNullable<
	IndexQuery['allCockpitMediaLogosCollection']['nodes']
>

const FeaturedBy = (props: { media: Media }) => {
	const { t } = useTranslation()
	const theme = useTheme()

	return (
		<>
			<H2Highlight>{t('courses:map.featured_by')}</H2Highlight>
			<div
				css={css`
					display: flex;
					max-width: 1024px;

					${maxWidth[2]} {
						flex-wrap: wrap;
						justify-content: center;
					}

					> div {
						display: flex;
						align-items: center;
						justify-content: center;
						max-width: 72px;
						margin: 1rem;

						img {
							max-width: 100%;
							filter: ${theme.imageFilter};
						}
					}
				`}>
				{props.media.map((mediaLogo) => (
					<div key={mediaLogo.mediaLogo.path}>
						<GatsbyImage
							image={
								mediaLogo?.mediaLogoSharp?.childImageSharp
									?.gatsbyImageData
							}
							alt={mediaLogo.title}
						/>
					</div>
				))}
			</div>
		</>
	)
}

export default FeaturedBy
