import styled from '@emotion/styled'
import { maxWidth } from 'src/themes'

const Container = styled.div`
	max-width: 1024px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;

	${maxWidth[2]} {
		padding: 0 1rem;
	}
`

export default Container
